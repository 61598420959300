import React from "react"
import Wave16 from '../../img/wave16.svg'
import  {ReactSVG}  from 'react-svg';
import Logo from '../../img/LogoPF.png'

export default function Footer(){
var dataAtual = new Date();


var anoAtual = dataAtual.getFullYear();


    return(

// <footer className="bg-green-base rounded-lg shadow dark:bg-teal-500 m-4">
//   <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
//     <div className="sm:flex sm:items-center sm:justify-between">
//       <a href="https://flowbite.com/" className="flex items-center mb-4 sm:mb-0">
//         <img src={Logo} className="h-8 mr-3" alt="Flowbite Logo" />
      
//       </a>
//       <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
//         <li>
//           <a href="#" className="mr-4 hover:underline md:mr-6 ">About</a>
//         </li>
//         <li>
//           <a href="#" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
//         </li>
//         <li>
//           <a href="#" className="mr-4 hover:underline md:mr-6 ">Licensing</a>
//         </li>
//         <li>
//           <a href="#" className="hover:underline">Contact</a>
//         </li>
//       </ul>
//     </div>
//     <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
//     <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© {anoAtual} <a href="https://flowbite.com/" className="hover:underline">PasseFácil™</a></span>
//   </div>
// </footer>


     <div className="relative  mt-40 bg-outras">
       <svg
         className="absolute top-0 w-full h-2 -mt-0 sm:-mt-4 sm:h-14 text-outras"
         preserveAspectRatio="none"
         viewBox="0 0 1440 54"
       >
         <path
           fill="currentColor"
           d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
         />
       </svg>
       <div className="px-2  pt-3 mx-auto sm:max-w-sm md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-2">
         <div className="grid gap-8 row-gap-10 mb-2 lg:grid-cols-6">
           <div className="md:max-w-sm lg:col-span-2">
             <a
               href="/"
               aria-label="Go home"
               title="Company"
               className=" items-center">
               <span className="ml-2  pt-2 text-xl font-bold tracking-wide text-gray-100 uppercase">
               <img src={Logo} className="h-12 mr-3" alt="Flowbite Logo" />
               </span>
             </a>
           
           </div>
       
         </div>
         <div className="flex flex-col justify-between pb-4  border-t border-deep-purple-accent-200 sm:flex-row">
           <p className="text-sm text-gray-100">
             © Copyright {anoAtual} PasseFácil
           </p>
           <div className="flex items-center mt-4 space-x-4 sm:mt-0">
             <a
               href="http://passefacil.com.br/Home/Terms"
               className="transition-colors duration-300 text-deep-purple-100 hover:text-card3"
             >
               {/* <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                 <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
               </svg> */}
               Política de Privacidade
             </a>
             <a
               href="/"
               className="transition-colors duration-300 text-deep-purple-100 hover:text-card3"
             >
               {/* <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                 <circle cx="15" cy="15" r="4" />
                 <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
               </svg> */}
             </a>
             <a
               href="/"
               className="transition-colors duration-300 text-deep-purple-100 hover:text-card3"
             >
               {/* <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                 <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
               </svg> */}
             </a>
           </div>
         </div>
       </div>
     </div>
   );
 };