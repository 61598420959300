import React from 'react';

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="relative bg-card3 p-6 md:p-8 rounded-lg shadow-lg max-w-md w-full mx-4 sm:mx-auto">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          &times;
        </button>
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Aviso Importante</h2>
        <p className="text-gray-700 mb-4">
          Informamos que nos dias <strong className="font-semibold">24 e 25 de junho de 2024 (segunda e terça-feira)</strong>, o atendimento será realizado no seguinte local:
        </p>
        <p className="text-gray-700 mb-4">
          <strong className="font-semibold">Local:</strong> Escola Municipal Abel Martins
        </p>
        <p className="text-gray-700 mb-4">
          <strong className="font-semibold">End:</strong> Av. Lalor Mota, nº. 551 - Carananduba/Mosqueiro
        </p>
        <p className="text-gray-700 mb-4">
          Nos dias <strong className="font-semibold">26, 27 e 28 de junho de 2024 (quarta, quinta e sexta-feira)</strong>, o atendimento ocorrerá em:
        </p>
        <p className="text-gray-700 mb-4">
          <strong className="font-semibold">Local:</strong> Escola Municipal Remígio Fernandez
        </p>
        <p className="text-gray-700 mb-4">
          <strong className="font-semibold">End:</strong>Tv. Maracacuera, s/nº. - Vila/Mosqueiro
        </p>
      </div>
    </div>
  );
};

export default Modal;
